.horizontal-slider {
  width: 100%;
  height: 2rem;
  top: 0.5rem;
}

.track {
}

.horizontal-slider .track {
  top: calc(0.5rem - 3px);
  height: 4px;
  cursor: pointer;
}

.horizontal-slider .track-0 {
  background-color: #fe9996;
}

.horizontal-slider .track-1{
  background-color: #e4dfd3;
}

.thumb {
  text-align: center;
  background-color: #fe9996;
  cursor: pointer;
}

.thumb:focus, .thumb.active:focus { outline: none; background-color: #fa7f7d;}
.thumb.active {
  background-color: #fe9996;
}

.horizontal-slider .thumb {
  top: -0.375rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 1rem;
}

